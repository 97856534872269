/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';
import { mix } from 'polished';

export const StyledPaylineWidgetWrapper = styled.div`
  #PaylineWidget {
    position: static;
    padding: 0 !important;

    .pl-modal-dialog {
      width: 100%;
      margin: 0 auto;
      padding: 0;
      box-shadow: none;
    }

    .pl-header {
      display: none !important;
    }

    .pl-body {
      background: transparent;
      border-radius: 0;
      padding-top: 0;
    }

    .pl-wallet-layout {
      background: transparent;
      border: 0;
      padding: 0;
    }

    .pl-walletrow,
    .pl-pmContainer {
      border: 0;
      box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      background: #f8f9fa;
    }

    .pl-wallet-infos-main {
      line-height: 1.3;
    }

    .pl-defaultLabel-container {
      right: auto;
      left: 65px;
      bottom: -2px;

      .pl-defaultLabel-icon {
        display: none;
      }
    }

    .pl-pay-btn,
    .pl-mng-btn-confirm.pl-confirm-yes {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      background-color: ${({ theme }) => theme.palette.primary.main};

      &:hover,
      &:active,
      &:focus {
        background-color: ${({ theme }) => mix(0.7, theme.palette.primary.main, '#000')};
      }
    }

    .pl-mng-btn.pl-mng-open-btn.pl-icon-pencil,
    .pl-mng-btn-confirm.pl-confirm-no {
      background-color: transparent;
      color: ${({ theme }) => theme.palette.primary.main};

      &:hover,
      &:active,
      &:focus {
        background-color: ${({ theme }) =>
          mix(0.8, theme.palette.common.white, theme.palette.primary.main)};
      }
    }

    .pl-mng-btns-container {
      color: ${({ theme }) => theme.palette.primary.main};
      border-left: 1px solid;
      border-color: #dcdcdc;
      border-radius: 10px;
      left: 70px;

      .pl-mng-sub-btn-container {
        background-color: ${({ theme }) => theme.palette.common.white};

        &:hover,
        &:active,
        &:focus {
          background-color: ${({ theme }) =>
            mix(0.8, theme.palette.common.white, theme.palette.primary.main)};
        }
      }
    }

    .pl-walletrowmng-closed .pl-mng-btns-container {
      left: 100%;
    }

    .pl-container-view .pl-has-error .pl-input {
      background-color: ${({ theme }) => theme.palette.common.white};
      border: 1px solid ${({ theme }) => theme.palette.error.main};
    }

    /* message success */
    .pl-message.pl-message-success {
      color: ${({ theme }) => theme.palette.primary.main};
    }

    /* CHECKBOX remenber me */
    .pl-remember-text {
      color: ${({ theme }) => theme.palette.common.black};
    }

    /* operation 'terminée'  */
    #pl-container-lightbox-body-title,
    .pl-message.pl-message-success,
    .pl-btn.pl-btn-success.pl-ticket-btn,
    .pl-btn.pl-btn-success.pl-payment-failure-btn,
    #pl-mgr-finalState-view,
    .pl-ticket-ticketKeep,
    .pl-btn.pl-btn-success.pl-token-expired-btn {
      display: none;
    }

    /* payment refused */
    .pl-image.pl-icon-smiley-sad,
    .pl-message.pl-message-error {
      color: ${({ theme }) => theme.palette.error.main};
    }

    .pl-ticket-view {
      margin: 0;
      background-color: #ebf9fb;
      padding: ${({ theme }) => theme.spacing()};
      box-shadow: ${(props) => props.theme.shadows[2]};
      border-radius: 4px;

      .pl-ticket {
        h3 {
          padding: 0;
          text-transform: none;
          font-weight: ${(props) => props.theme.typography.fontWeightBold};
          text-align: left;
          margin: ${({ theme }) => theme.spacing(0.5)} 0;
        }
        .pl-ticket-content {
          background: none;
          padding: 0;
          font-weight: ${(props) => props.theme.typography.fontWeightSemiBold};

          .pl-ticket-security {
            display: none;
          }

          ul.pl-ticket-payment-details {
            li {
              margin: ${({ theme }) => theme.spacing(0.5)} 0;

              * {
                font-size: ${({ theme }) => theme.typography.caption.fontSize};
              }
              div {
                margin: 0;
              }
              span:first-child {
                font-weight: ${(props) => props.theme.typography.fontWeightSemiBold};
              }

              .pl-ticket-ticketAmount,
              .pl-ticket-ticketAmount span:first-child {
                font-size: ${({ theme }) => theme.typography.fontSize}px;
                font-weight: ${(props) => props.theme.typography.fontWeightBold};
              }

              &:nth-child(3),
              &:nth-child(5),
              &:nth-child(7),
              &:nth-child(8),
              &:nth-child(10) {
                display: none;
              }
            }
          }
        }
      }
    }
    .pl-failure,
    .pl-onhold-partner,
    .pl-payment-canceled,
    .pl-payment-failure,
    .pl-token-expired {
      padding: 0;

      .pl-reason {
        color: ${(props) => props.theme.palette.common.black};
      }

      .pl-image {
        color: ${(props) => props.theme.palette.error.main};
      }
    }
  }
`;
